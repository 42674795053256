const accessTokenCookieName = 'accessToken';
const accessTokenUrlName = 'access_token';

export const hasAccessTokenCookie = () => getCookieAccessToken();
export const hasAccessTokenUrl = () => getUrlParam(accessTokenUrlName);

export const getJwtToken = (): string | undefined => {
    const urlAccessToken = getUrlParam(accessTokenUrlName);
    const expirationSeconds = getUrlParam('expires_in');
    const cookieAccessToken = getCookieAccessToken();
    if (urlAccessToken && expirationSeconds) {
        const jwtTokenObject = {
            jwtToken: urlAccessToken,
            payload: {
                token_use: 'access',
            },
        };
        setCookie(accessTokenCookieName, encodeURIComponent(JSON.stringify(jwtTokenObject)), expirationSeconds);
        return urlAccessToken;
    } else if (cookieAccessToken) {
        return cookieAccessToken;
    }
    return undefined;
};

export const getCookie = (name: string): any => {
    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${name}=`);
    if (parts && parts.length === 2) {
        return parts
            .pop()!
            .split(';')
            .shift();
    }
};
export const setCookie = (name: string, value: string, seconds: string) => {
    const date = new Date();
    date.setTime(date.getTime() + parseInt(seconds) * 1000);
    const expires = '; expires=' + date.toUTCString();
    document.cookie = name + '=' + (value || '') + expires + '; path=/;domain=.accenture.com';
};
export const deleteCookie = (name: string) => {
    const path = '/';
    const domain = '.accenture.com';
    if (getCookie(name)) {
        document.cookie =
            name +
            '=' +
            (path ? ';path=' + path : '') +
            (domain ? ';domain=' + domain : '') +
            ';expires=Thu, 01 Jan 1970 00:00:01 GMT';
    }
};
export const getCookieAccessToken = () =>
    getCookie(accessTokenCookieName)
        ? JSON.parse(decodeURIComponent(getCookie(accessTokenCookieName))).jwtToken
        : undefined;
export const getUrlParam = (sParam: any) => {
    var sPageURL = window.location.hash.substring(1);
    var sURLVariables = sPageURL.split('&');
    for (var i = 0; i < sURLVariables.length; i++) {
        var sParameterName = sURLVariables[i].split('=');
        if (sParameterName[0] === sParam) {
            return sParameterName[1];
        }
    }
};
export const handleRedirect = (psDomain: string) => {
    const existingPath = getCookie('existingPath') || '';
    const existingRoute = getCookie('existingRoute') || '';
    deleteCookie('existingPath');
    deleteCookie('existingRoute');

    if (existingPath || existingRoute) {
        window.location.href = `https://www.${psDomain}${existingRoute}${existingPath}`;
    } else {
        if (window.location.hash.startsWith('#access_token=')) {
            try {
                const redirectState = getUrlParam('state') || '';
                const decodedState = decodeURIComponent(redirectState);
                const stateObject = JSON.parse(decodedState);
                if (typeof stateObject === 'object' && stateObject !== null) {
                    window.location.href = `https://www.${psDomain}/${stateObject.path || ''}`;
                } else {
                    window.location.href = `https://www.${psDomain}/portal`;
                }
            } catch (e) {
                window.location.href = `https://www.${psDomain}/portal`;
            }
        } else {
            window.location.href = `https://www.${psDomain}/portal`;
        }
    }
};
