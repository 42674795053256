import {Spinner} from '@acp/lib-ui-react-common';
import React, {useEffect, useState} from 'react';
import {Amplify, Auth} from 'aws-amplify';
import {getAuthorisationSignedCookies, getLoginStatus} from './modules/service/api';
import {Login} from './modules/login/Login';
import styles from './App.module.scss';
import {getJwtToken, hasAccessTokenUrl, hasAccessTokenCookie, handleRedirect, setCookie} from './modules/utils/helper';

export const App = () => {
    const [isLoading, setLoading] = useState(true);
    const [errors, setErrors] = useState({
        hasExpiredSession: false,
        hasLoginError: false,
        hasSetupError: true,
    });
    const [loginStatus, setLoginStatus] = useState({
        userPoolId: '',
        webAppClientId: '',
        idps: {
            Providers: [
                {
                    ProviderName: '',
                    ProviderType: '',
                    LastModifiedDate: '',
                    CreationDate: '',
                },
            ],
        },
        ssoCookieName: '',
        psDomain: '',
        oauth: {
            scope: [''],
            redirectSignIn: '',
            redirectSignOut: '',
        },
        oktaHost: '',
    });

    const getSignedCookies = async (jwtToken: string): Promise<boolean> => {
        try {
            const signedCookies = await getAuthorisationSignedCookies(jwtToken);
            return signedCookies ? true : false;
        } catch (e) {
            return false;
        }
    };

    useEffect(() => {
        const jwtToken = getJwtToken();

        if (window.location.hash && !window.location.hash.startsWith('#access_token=')) {
            const path = window.location.hash;
            setCookie('existingPath', path, '3600');
        }
        if (
            window.location.pathname &&
            window.location.pathname !== '/' &&
            window.location.pathname !== '/ui-login/master/index.html'
        ) {
            const path = window.location.pathname;
            setCookie('existingRoute', path, '3600');
        }
        setLoading(true);
        setErrors({
            hasExpiredSession: false,
            hasLoginError: false,
            hasSetupError: false,
        });
        (async () => {
            try {
                const loginStatus = await getLoginStatus();
                setLoginStatus(loginStatus.data);
                if (jwtToken) {
                    const signedCookies = jwtToken && (await getSignedCookies(jwtToken));
                    if (signedCookies) {
                        console.info('Valid session found, cookies have been set. redirecting.');
                        handleRedirect(loginStatus.data.psDomain);
                    } else {
                        if (hasAccessTokenUrl()) {
                            setErrors({
                                ...errors,
                                hasLoginError: true,
                            });
                        }

                        if (hasAccessTokenCookie()) {
                            setErrors({
                                ...errors,
                                hasExpiredSession: true,
                            });
                        }
                        setLoading(false);
                    }
                } else {
                    const loginSetup = {...loginStatus.data};
                    const domain =
                        loginSetup.psDomain && loginSetup.psDomain.indexOf('icm') === 0
                            ? `prod-${loginSetup.psDomain.replace(/\./g, '-')}.auth.us-east-1.amazoncognito.com`
                            : `${loginSetup.psDomain.replace(/\./g, '-')}.auth.us-east-1.amazoncognito.com`;
                    Amplify.configure({
                        Auth: {
                            region: 'us-east-1',
                            userPoolId: loginSetup.userPoolId,
                            userPoolWebClientId: loginSetup.webAppClientId,
                            mandatorySignIn: false,
                            oauth: {
                                domain: domain,
                                scope: loginSetup.oauth.scope,
                                redirectSignIn: loginSetup.oauth.redirectSignIn,
                                redirectSignOut: loginSetup.oauth.redirectSignOut,
                                responseType: 'token',
                            },
                        },
                    });
                    const accentureIdp: any = 'accenture.com';
                    Auth.federatedSignIn({provider: accentureIdp});
                }
            } catch (e) {
                setErrors({
                    ...errors,
                    hasSetupError: true,
                });
                setLoading(false);
            }
        })();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const checkHttpProtocol = () => {
        if (window.location.protocol !== 'https:') {
            window.location.replace(`https:${window.location.href.substring(window.location.protocol.length)}`);
        }
    };

    const loginObject = {...loginStatus, ...errors};

    return (
        <div>
            {isLoading && (
                <div className={styles.wrapperLoader}>
                    <div className={styles.spinnerContainer}>
                        <Spinner>
                            <div>Starting application...</div>
                            {checkHttpProtocol() as any}
                        </Spinner>
                    </div>
                </div>
            )}
            {!isLoading && <Login {...loginObject} />}
        </div>
    );
};
