import {AuthSetup} from '../mocks/authorisation.mock';
import {axiosPs} from './axiosPs';

const cachedData: {
    [key: string]: {
        okta: boolean;
    };
} = {};

export const getLoginStatus = async (): Promise<{data: AuthSetup}> => {
    return axiosPs.get('/authorisation/login/setup');
};

export const getIdpOktaConfig = async (idp: string): Promise<{okta: boolean}> => {
    const url = `/authorisation/idp/v1/domain/${idp}`;
    if (cachedData[url]) {
        return Promise.resolve(cachedData[url]);
    } else {
        return axiosPs
            .get(url)
            .then(res => {
                cachedData[url] = res.data;
                return res.data;
            })
            .catch(e => {
                cachedData[url] = {okta: false};
                return e;
            });
    }
};

export const getAuthorisationSignedCookies = async (jwtToken: string): Promise<{data: object}> => {
    return axiosPs.get('/authorisation/security/sign-cookies', {
        withCredentials: true,
        headers: {
            Authorization: `Bearer ${jwtToken}`,
        },
    });
};
